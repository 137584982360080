import React, { Component } from "react";

export class Header extends Component {
  render() {
    return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    {this.props.data ? this.props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <h3>
                    {this.props.data ? this.props.data.info : "Loading"}
                    <span></span>
                  </h3>
                  <p style={{ color: 'orange' }}>
                    {this.props.data ? this.props.data.paragraph : "Loading"}
                  </p>
                  <div className="container" style={{ display: 'contents'}}>
                    <div className="centered-item">
                    <a
                      style={{
                        float: 'none',
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://be-well-healing-tramore.sumupstore.com/"
                      className="btn btn-custom-og btn-lg page-scroll"
                    >
                      Visit My Orgone Generator Store
                    </a>
                      <br /><br />
                      <a
                        href="#services"
                        className="btn btn-custom btn-lg page-scroll"
                      >
                        Learn More About Physical Therapy
                      </a>{" "} <br /> <br />
                      <a
                        href="#redlight"
                        className="btn btn-custom btn-lg page-scroll"
                      >
                        Learn More About Red Light Therapy
                      </a>{" "} 
                      <a style={{ float: 'none' }} target="_blank" rel="noopener noreferrer"  
                        href="https://booking.setmore.com/scheduleappointment/72eadd37-3402-49e2-a6ae-9562641d752c">
                        <img border="none" src="https://storage.googleapis.com/full-assets/setmore/images/1.0/Settings/book-now-blue.svg" 
                        alt="Book an appointment with Nigel O'Connor using Setmore"/>
                      </a>
                      <br /> <br />
                      <a
                        href="#lightcode"
                        className="btn btn-custom btn-lg page-scroll"
                      >
                        Learn More About Light Code Healing
                      </a>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
