import React, { Component } from 'react'

export class redlight extends Component {
  render() {
    return (
        <div id="redlight">
        <div className="container" style={{ color: 'lightred' }}>
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/RLCombined2.jpg" className="img-responsive" alt=""/> </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>Photobiomodulation - Red Light/NIR</h2>
                <p>{this.props.data ? this.props.data.red : 'loading...'}</p>
                <p>{this.props.data ? this.props.data.light : 'loading...'}</p>
                <p>{this.props.data ? this.props.data.therapy : 'loading...'}</p>
                <p>{this.props.data ? this.props.data.heals : 'loading...'}</p>
                <p>{this.props.data ? this.props.data.you : 'loading...'}</p>
                <a style={{ float: 'none' }} target="_blank" rel="noopener noreferrer"  
                  href="https://booking.setmore.com/scheduleappointment/72eadd37-3402-49e2-a6ae-9562641d752c">
                  <img border="none" src="https://storage.googleapis.com/full-assets/setmore/images/1.0/Settings/book-now-blue.svg" 
                  alt="Book A 30 Min Session"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default redlight
