import React, { Component } from 'react'

export class lightcode extends Component {
  render() {
    return (
        <div id="lightcode">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/lightcode1.jpg" className="img-responsive" alt=""/> </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>Light Code Healing</h2>
                <p>{this.props.data ? this.props.data.love : 'loading...'}</p>
                <p>{this.props.data ? this.props.data.truth : 'loading...'}</p>
                <p>{this.props.data ? this.props.data.light : 'loading...'}</p>
                <p>{this.props.data ? this.props.data.am : 'loading...'}</p>
                <p>{this.props.data ? this.props.data.compassion : 'loading...'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default lightcode
